import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Dashboard.css"; // Import the CSS file for styling
import Custom_pagination from "../../services/Custom_pagination";
import Header from "../headerfooter/Header";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [villages, setVillages] = useState([]); // List of villages
  const [filteredVillages, setFilteredVillages] = useState([]); // Filtered village data
  const [selectedVillage, setSelectedVillage] = useState(null); // Store the selected village for viewing info
  const [searchQuery, setSearchQuery] = useState(""); // Search input state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [recordsPerPage, setRecordsPerPage] = useState(5); // Records per page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [showVillages,setShowVillages]=useState([])
  const [candidate_id, setCandidateId] = useState();
  const token = localStorage.getItem("token");
   const navigate = useNavigate();

  useEffect(() => {
    const fetchVillages = async () => {
      if (candidate_id !== "") {
        try {
          const response = await axios.get(
            `https://village.pcube.in.net/api/village/${
              candidate_id !== "" && candidate_id ? candidate_id : "all"
            }`
          );
          setVillages(response.data.data); // Set the list of villages
          // setFilteredVillages(response.data.data); // Initialize filtered villages
        } catch (err) {
          setError("Error fetching villages.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchVillages();
  }, [candidate_id]);

  useEffect(() => {
    setFilteredVillages(
      villages.filter((village) =>
        village.village_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setTotalPages(Math.ceil(filteredVillages.length / recordsPerPage));
    if(filteredVillages.length <=0){
      setShowVillages([])
    }
   // Update total pages when filtered villages or records per page change
  }, [searchQuery, villages, recordsPerPage, filteredVillages.length]);

  // get id from url 
   useEffect(() => {
     const queryParams = new URLSearchParams(window.location.search);
     const id = queryParams.get("id");
     if (id) {
       setCandidateId(id);
       console.log("Initial Village ID:", id); // Log the initial ID
     }
   }, []);


  const handleSetId = () => {
     const newUrl = window.location.href.replace(
       new RegExp(`/dashboard/?id=`),
       `/dashboard/?id=`
     );

       window.history.pushState({}, "", newUrl);

       
  };
  handleSetId()
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
const [images,setImages]=useState([])
  const handleViewInfo = (village) => {
    console.log(village)
  if (village?.images) setImages(village?.images?.split(","));
        
console.log(images)
    setSelectedVillage(village);
    setShowModal(true);
  };
console.log(images);

  const closeModal = () => {
    setShowModal(false);
    setSelectedVillage(null);
  };

  const getYoutubeId = (url) => {
    const regExp =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };
console.log(candidate_id);
console.log(token);
const goTOVillageInfo= ()=>{
  navigate("/village-info");
}


   const goTOlogin = () => {
     navigate("/login");
   };

   const handleLogout = () => {
     let confirmation = window.confirm("Are you sure you want to log out");
     if (confirmation) {
       localStorage.removeItem("token");
       localStorage.removeItem("adminId");
       localStorage.removeItem("candidateId");
       navigate("/dashboard");
     }
   };


  return (
    <>
      {token ? (
        <Header isSideBar={true} handleLogout={handleLogout} />
      ) : (
        <button onClick={goTOlogin} style={{ opacity: 1 }}></button>
      )}
      <div className="dashboard-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>गावांची यादी</h2>
          {token && (
            <button
              type="button"
              style={{
                backgroundColor: "#4CAF50", // Green background
                color: "white", // White text
                padding: "10px 20px", // Padding
                marginBottom: "10px", //
                border: "none", // No border
                borderRadius: "5px", // Rounded corners
                height: "50%",
                cursor: "pointer", // Pointer cursor on hover
                transition: "background-color 0.3s ease", // Smooth transition on hover
              }}
              onClick={goTOVillageInfo}
            >
              माहिती भरण्यासाठी जा
            </button>
          )}
        </div>

        <div className="search-container">
          <label htmlFor="searchVillage">गाव शोधा:</label>
          <input
            type="text"
            id="searchVillage"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="शोधण्यासाठी गावाचे नाव लिहा "
          />
        </div>

        {loading && <p className="loading-message">गावे लोड होत आहेत...</p>}
        {error && <p className="error-message">{error}</p>}

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>अनुक्रमांक</th>
                <th>गावांची नावे</th>
                <th>क्रिया</th>
              </tr>
            </thead>
            <tbody>
              {showVillages?.map((village, index) => (
                <tr key={village.id}>
                  <td>{index + 1}</td>
                  <td>{village.village_name}</td>
                  <td>
                    <button
                      className="view-button"
                      onClick={() => handleViewInfo(village)}
                    >
                      माहिती पहा
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filteredVillages.length > 0 && (
          <Custom_pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recordsPerPage={recordsPerPage}
            setRecordsPerPage={setRecordsPerPage}
            totalPages={totalPages}
            villages={filteredVillages}
            setShowVillages={setShowVillages}
          />
        )}

        {filteredVillages.length === 0 && !loading && (
          <p className="no-data-message">No villages found.</p>
        )}

        {showModal && selectedVillage && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Village Information: {selectedVillage.village_name}</h3>
              <hr />
              <p>
                <strong>Description:</strong> {selectedVillage.description}
              </p>
              <hr />
              {[
                selectedVillage.youtube_link1,
                selectedVillage.youtube_link2,
              ].map(
                (link, index) =>
                  link && (
                    <div className="youtube-links" key={index}>
                      <p>
                        <strong>YouTube Video {index + 1}:</strong>
                      </p>
                      <iframe
                        width="100%"
                        height="315"
                        src={`https://www.youtube.com/embed/${getYoutubeId(
                          link
                        )}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={`YouTube Video ${index + 1}`}
                      ></iframe>
                    </div>
                  )
              )}
              <hr />
              {[...images, selectedVillage.image1, selectedVillage.image2].map(
                (image, index) =>
                  image && (
                    <div className="image-gallery" key={index}>
                      <img
                        src={`https://village.pcube.in.net/uploads/${selectedVillage.candidate_id}/${image}`}
                        alt={`${selectedVillage.village_name}`}
                        style={{ maxWidth: "100%", marginBottom: "10px" }}
                      />
                    </div>
                  )
              )}
              <button className="close-modal-button" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
