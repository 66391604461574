import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import Header from "../headerfooter/Header"; // Adjusted import path
import SideBar from "../headerfooter/SideBar";
import { Button, Col, Modal, Row } from "antd";
import VillageInfoEdit from "./VillageInfoEdit";
import { data } from "autoprefixer";

const VillageInfo = () => {
  const [villageName, setVillageName] = useState("");
  const [description, setDescription] = useState("");
  const [youtubeLink1, setYoutubeLink1] = useState("");
  const [youtubeLink2, setYoutubeLink2] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [imageWarning, setImageWarning] = useState("");
  const [villageNames, setVillageNames] = useState([]);
  const [isOpen, setOpen] = useState(false); // Ensure setOpen is defined correctly here
  const [datafield, setDataField] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const formRef = useRef(null);
  const navigate = useNavigate();

  // Modal logic
  const showModal = () => {
    setIsModalOpen(true);
  };

const handleOk = async (e) => {
  e.preventDefault(); // Prevent default form submission
  const formData = new FormData(formRef.current); // Create a FormData object from the form

  const token = localStorage.getItem("token"); // Get the token from localStorage
  formData.append("village_name", modalData?.village_name); // Append the village_name to FormData

  try {
    const response = await axios.post(
      `https://village.pcube.in.net/api/village/edit/${modalData?.id}/${modalData?.village_name}`, // Your API endpoint
      formData, // Send the FormData
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token for authorization
          "Content-Type": "multipart/form-data", // Set the content type
          candidate_id: localStorage.getItem("candidateId"), // Custom header if needed
        },
      }
    );

    console.log(response); // Log the response
    toast.success("Files uploaded successfully"); // Show success toast
    formRef.current.reset(); // Reset the form
  } catch (error) {
    console.error("Error while uploading files", error); // Log any errors
    toast.error("Error while uploading files"); // Show error toast
  }
};


  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleImageChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const isValidSize = file.size >= 50 * 1024 && file.size <= 1024 * 1024;
      const isValidType =
        file.type === "image/jpeg" || file.type === "image/png";

      if (!isValidSize || !isValidType) {
        setImageWarning(
          "Image must be between 50 KB and 1024 KB and in JPG or PNG format."
        );
      } else {
        setImageWarning("");
        setImage(file);
      }
    } else {
      setImage(file);
      setImageWarning("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formValues = Object.fromEntries(formData.entries());

    if (!formValues.village_name) {
      toast.error("Village Name is required");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("You are not logged in. Please log in.");
      navigate("/login");
      return;
    }

    formData.append("admin_id", localStorage.getItem("adminId"));
    formData.append("candidate_id", localStorage.getItem("candidateId"));

    try {
      const response = await axios.post(
        "https://village.pcube.in.net/api/village/add",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            candidate_id: localStorage.getItem("candidateId"),
          },
        }
      );

      if (response.data.status_code === 401) {
        toast.error("Unauthorized: Please log in again.");
        navigate("/login");
      } else if (response.data.status_code === 200) {
        toast.success("Village added successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Error in adding village");
      }

      setVillageName("");
      setDescription("");
      setYoutubeLink1("");
      setYoutubeLink2("");
      setImage1(null);
      setImage2(null);
      setImageWarning("");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized: Please log in again.");
        navigate("/login");
      } else {
        toast.error("Error in adding village");
      }
    }
  };

  function fetchcandidate_data(candidate_id) {
    axios
      .get(`https://village.pcube.in.net/api/village/all/${candidate_id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((response) => {
        setVillageNames(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching candidate data:", error);
      });
  }

  useEffect(() => {
    if (isOpen) {
      fetchcandidate_data(localStorage.getItem("candidateId"));
    }
  }, [isOpen]);

  const handleLogout = () => {
    let confirmation=window.confirm("Are you sure you want to log out");
    if(confirmation)
    { 
        localStorage.removeItem("token");
      localStorage.removeItem("adminId");
      localStorage.removeItem("candidateId");
      navigate("/login");
    }
  };

  const addVillageData = (data) => {
    if (data) {
      try {
        console.log(data);
        setModalData(data);
        setIsModalOpen(true);
      } catch (error) {
        console.log("Error uploading");
      }
    } else {
      console.log("No data found");
    }
  };
  ;
  const editVillageData = (data) => {
    if (data) {
      try {
        console.log(data);
        setModalData(data);
        setIsModalOpen(true);
      } catch (error) {
        console.log("Error uploading");
      }
    } else {
      console.log("No data found");
    }
  };

  const deleteVillage = (id) => {
    const confirmed = window.confirm("Are you sure you want to delete this?");
    if (confirmed) {
      axios
        .delete(`https://village.pcube.in.net/api/village/delete/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          console.log(response?.data?.message);
          toast.success(response?.data?.message);
        })
        .catch((error) => {
          console.error("Error deleting village:", error);
          toast.error("Error deleting village");
        });
    } else {
      toast.error("Village deletion cancelled");
    }
  };

  const goTODashboard = () => {
    navigate("/dashboard");
  };
  let isedit=false;
  return (
    <>
      <Header
        handleLogout={handleLogout}
        isSideBar={true}
        isOpen={isOpen}
        setOpen={setOpen}
      />
      <SideBar
        isSideBar={true}
        villageNames={villageNames}
        deleteVillage={deleteVillage}
        editVillageData={editVillageData}
        isOpen={isOpen}
        setOpen={setOpen}
        addVillageData={addVillageData}
      />
      <div className="village-info-container" style={{ paddingRight: "40px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>गावांची विकास कामांची माहिती भरा.</h2>
          <button
            type="button"
            className="reouter-button"
            style={{ padding: "1px" }}
            onClick={goTODashboard}
          >
            विकास कामे बघा.
          </button>
        </div>
        {isedit ? (
          <VillageInfoEdit data={data} />
        ) : (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <input type="text" placeholder="गावाचे नाव" name="village_name" />
            <textarea
              placeholder="माहिती"
              value={description}
              name="description"
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <input
              type="text"
              placeholder="यूट्यूब लिंक १"
              value={youtubeLink1}
              name="youtube_link1"
              onChange={(e) => setYoutubeLink1(e.target.value)}
            />
            <input
              type="text"
              placeholder="यूट्यूब लिंक २"
              name="youtube_link2"
              value={youtubeLink2}
              onChange={(e) => setYoutubeLink2(e.target.value)}
            />
            <input
              type="file"
              name="image1"
              onChange={(e) => handleImageChange(e, setImage1)}
            />
            <input
              type="file"
              name="image2"
              onChange={(e) => handleImageChange(e, setImage2)}
            />
            {imageWarning && (
              <div className="warning-message">{imageWarning}</div>
            )}
            <button type="submit">माहिती सेव करा</button>
          </form>
        )}
        <div className="image-requirements">
          <p>
            <strong>प्रतिमा आवश्यकताः</strong>
          </p>
          <ul>
            <li>फोटो आकार: ५० केबी ते १०२४ केबी</li>
            <li>स्वरूपे: JPG किंवा PNG</li>
          </ul>
        </div>
      </div>

      <ToastContainer />

      <Modal
        title="Upload Images"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row style={{ display: "flex", justifyContent: "end" }}>
          <Button
            name="Add Field"
            onClick={() => {
              setDataField((prevDatafield) => [
                ...prevDatafield,
                { [`file-${prevDatafield.length + 1}`]: "" },
              ]);
            }}
            style={{ backgroundColor: "black", color: "white" }}
          >
            आणखी फोटो
          </Button>
        </Row>

        <form ref={formRef} id="image-upload" onSubmit={handleOk}>
          {datafield.map((field, index) => (
            <Row key={index}>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ padding: "0.3em", marginBottom: "0.3em" }}
              >
                <input
                  type="file"
                  placeholder={`Field ${index + 1}`}
                  name="files"
                  style={{ padding: "0.3em", width: "100%" }}
                  required
                />
                {modalData[`filed-${index + 1}`] && (
                  <div>Current Value: {modalData[`filed-${index + 1}`]}</div>
                )}
              </Col>
            </Row>
          ))}
          <Row style={{ display: "flex", justifyContent: "end" }}>
            <button type="submit">सेव करा</button>
          </Row>
        </form>
      </Modal>
    </>
  );
};

export default VillageInfo;
