import React, { useEffect } from "react";
import "../components/Admin/Dashboard.css";

function Custom_pagination({
  currentPage,
  setCurrentPage,
  recordsPerPage,
  setRecordsPerPage,
  totalPages,
  villages,
  setShowVillages,
}) {
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentVillages = villages.slice(indexOfFirstRecord, indexOfLastRecord);
console.log(villages,indexOfFirstRecord,indexOfLastRecord)
  useEffect(() => {
    setShowVillages(currentVillages);
    console.log(currentVillages)
  }, [recordsPerPage, villages, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageChange = (event) => {
    const selectedPage = Number(event.target.value);
    setCurrentPage(selectedPage);
  };

  const handleRecordsPerPageChange = (event) => {
    const newRecordsPerPage = Number(event.target.value);
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1); 
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <select
        value={recordsPerPage}
        onChange={handleRecordsPerPageChange}
        className="records-per-page-select"
      >
        <option value={5}>5 नोंदी प्रत्येक पृष्ठावर</option>
        <option value={10}>10 नोंदी प्रत्येक पृष्ठावर</option>
        <option value={15}>15 नोंदी प्रत्येक पृष्ठावर</option>
      </select>

      <div
        style={{
          width: "170px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          मागे
        </button>

        <select
          value={currentPage}
          onChange={handlePageChange}
          className="pagination-select"
          style={{ width: "40px", padding: "4px" }}
        >
          {Array.from({ length: totalPages }, (_, index) => (
            <option key={index + 1} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>

        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          पुढे
        </button>
      </div>
    </div>
  );
}

export default Custom_pagination;
