import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./style.css";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://village.pcube.in.net/api/auth/register", {
        username,
        password,
        candidateId,
      });

      // Show success toast and navigate to login page
      toast.success("Registration successful! Redirecting to login...", {
        position: "top-right", // Corrected here
      });

      setTimeout(() => {
        navigate("/login");
      }, 2000); // Delay navigation to show the toast
    } catch (err) {
      toast.error(err.response?.data?.message || "Registration failed", {
        position: "top-right", // Corrected here
      });
    }
  };

  return (
    <div
      className="registration-container"
      style={{ background: "linear-gradient(45deg, #327e7c, #7cc561a3)" }}
    >
      <h2>Admin Registration</h2>
      <form onSubmit={handleRegister}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Candidate ID:</label>
          <input
            type="text"
            value={candidateId}
            onChange={(e) => setCandidateId(e.target.value)}
            required
          />
        </div>
        <button type="submit">Register</button>
      </form>
      <button onClick={() => navigate("/login")} style={{ marginTop: "20px" }}>
        Go to Login
      </button>

      {/* Toastify container */}
      <ToastContainer />
    </div>
  );
};

export default Register;
