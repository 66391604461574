import PoweroffOutlined from '@ant-design/icons/PoweroffOutlined';
import AlignLeftOutlined from "@ant-design/icons/AlignLeftOutlined";
import FileAddOutlined from '@ant-design/icons/FileAddOutlined';

import React from 'react'

function Header({ handleLogout, isSideBar=false, isOpen, setOpen }) {

  return (
    <div style={{ height: "100px", width: "100%" }}>
      {isSideBar && !isOpen && (
        <button
          onClick={() =>setOpen && setOpen(!isOpen)}
          style={{
            float: "left",
            margin: "auto",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
          }}
        >
          <AlignLeftOutlined />
        </button>
      )}
      <button
        onClick={handleLogout}
        style={{
          float: "right",
          margin: "auto",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
        }}
      >
        <PoweroffOutlined />
      </button>
    </div>
  );
}

export default Header